import { createHttpLink } from '@apollo/client';

export const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_API,
  headers: {
    'Access-Control-Request-Method': 'POST',
  },
});

export const httpLinkOld = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_API_OLD,
  headers: {
    'Access-Control-Request-Method': 'POST',
  },
});

export const searchHttpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_SEARCH_API,
  headers: {
    'Access-Control-Request-Method': 'POST',
  },
});

export const searchHttpLinkOld = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_SEARCH_API_OLD,
  headers: {
    'Access-Control-Request-Method': 'POST',
  },
});
