import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

export const wsLink = () =>
  new GraphQLWsLink(
    createClient({
      url: import.meta.env.VITE_GRAPHQL_SEARCH_WS,
    })
  );

export const wsLinkOld = () =>
  new GraphQLWsLink(
    createClient({
      url: import.meta.env.VITE_GRAPHQL_SEARCH_WS_OLD,
    })
  );
