import { ApolloClient, ApolloLink, ApolloProvider as DefaultApolloProvider, type Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { authLink } from './authLink';
import { cache } from './cache';
import { errorLink } from './errorLink';
import { httpLink, searchHttpLink, httpLinkOld, searchHttpLinkOld } from './httpLink';
import { traceHeaderLink } from './traceHeaderLink';
import { wsLink, wsLinkOld } from './wsLink';
import { useFeature } from 'core/flags/useFeature';

export const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const hasBackendJwtOnly = useFeature('FEAT_QAI_2755_RemoveJsJwtCookie');

  // We can't leverage flags at this level because the ApolloProvider is a parent of the FeatureHubFlagProvider
  // (our flags depend on auth, which depends on apollo). So we have to provide the separate clients here and
  // choose the correct one in the places we run our queries.
  const client = useMemo(() => {
    const oldSearchLink = ApolloLink.from([
      errorLink(navigate),
      authLink(getAccessTokenSilently, !hasBackendJwtOnly),
      traceHeaderLink,
      searchHttpLinkOld,
    ]);
    const newSearchLink = ApolloLink.from([
      errorLink(navigate),
      authLink(getAccessTokenSilently, !hasBackendJwtOnly),
      traceHeaderLink,
      searchHttpLink,
    ]);
    const oldSearchWsLink = wsLinkOld();
    const newSearchWsLink = wsLink();
    const oldMetadataLink = ApolloLink.from([
      errorLink(navigate),
      authLink(getAccessTokenSilently, !hasBackendJwtOnly),
      httpLinkOld,
    ]);
    const newMetadataLink = ApolloLink.from([
      errorLink(navigate),
      authLink(getAccessTokenSilently, !hasBackendJwtOnly),
      httpLink,
    ]);

    const link = ApolloLink.split(
      operation => shouldUseOldSearchApi(operation),
      ApolloLink.split(operation => shouldUseWebsockets(operation), oldSearchWsLink, oldSearchLink),
      ApolloLink.split(
        operation => shouldUseNewSearchApi(operation),
        ApolloLink.split(operation => shouldUseWebsockets(operation), newSearchWsLink, newSearchLink),
        ApolloLink.split(operation => shouldUseNewMetadataApi(operation), newMetadataLink, oldMetadataLink)
      )
    );

    return new ApolloClient({
      link,
      cache,
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'ignore',
          notifyOnNetworkStatusChange: true,
        },
        query: {
          errorPolicy: 'all',
          notifyOnNetworkStatusChange: true,
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });
  }, [navigate, getAccessTokenSilently, hasBackendJwtOnly]);

  return <DefaultApolloProvider client={client}>{children}</DefaultApolloProvider>;
};

function shouldUseOldSearchApi(operation: Operation) {
  return operation.getContext().clientName === 'search-old';
}

function shouldUseNewSearchApi(operation: Operation) {
  return operation.getContext().clientName === 'search';
}

function shouldUseNewMetadataApi(operation: Operation) {
  return operation.getContext().clientName === 'metadata';
}

function shouldUseWebsockets(operation: Operation) {
  const definition = getMainDefinition(operation.query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}
