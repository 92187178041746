import type { Components, CSSInterpolation, Theme } from '@mui/material';
import type { OverridesStyleRules } from '@mui/material/styles/overrides';
import { createThemeProxy } from './createThemeProxy';

const rootButton: CSSInterpolation = {
  gap: '0.25rem',
  boxShadow: 'none',
  ':hover': { boxShadow: 'none' },
  fontWeight: 600,
  ':not(.honorCase)': {
    textTransform: 'capitalize',
  },
  textTransform: 'none',
};

export const largeButtonSize: CSSInterpolation = {
  height: '2.5rem',
  padding: '0.5rem 0.75rem',
};

export const mediumButtonSize: CSSInterpolation = {
  height: '2rem',
  padding: '0.4375rem 0.75rem',
};

export const smallButtonSize: CSSInterpolation = {
  height: '1.5rem',
  padding: '0.1875rem 0.75rem',
};

const rootIconButton: CSSInterpolation = {
  gap: '0.625rem',
  boxShadow: 'none',
  ':hover': { boxShadow: 'none' },
  fontWeight: 600,
};

const labStylings = {
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: '12px 0',
      },
    },
  },
} as Components<Omit<Theme, 'components'>>;

const spacing = 8;

export const muiTheme = createThemeProxy({
  spacing,
  typography: {
    fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
    // We also have to set the font family on custom variants due to a bug.
    // See: https://github.com/mui/material-ui/issues/35939
    // This issue only surfaced in stories due to styles applied globally in app
    link: {
      fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
      fontWeight: 600,
      cursor: 'pointer',
    },
    button: {
      fontSize: '.875rem',
      textTransform: 'capitalize',
      fontWeight: 600,
    },
    // Page Title
    h1: {
      fontSize: '1.5rem',
      // This might need to change 800 if the DS is accurate:
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: '0.04375rem',
    },
    // Page Subtitle
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: '0.025rem',
    },
    // Section Title
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      letterSpacing: '0.0375rem',
    },
    // Section Subtitle
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    // Container Title
    h5: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
      letterSpacing: '0.025rem',
    },
    tableHead: {
      fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '0.875rem',
      letterSpacing: '0.025rem',
      textTransform: 'uppercase',
    },
    baseSemibold: {
      fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
      letterSpacing: '0rem',
    },
    baseSmall: {
      fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 600,
      letterSpacing: '0.4px',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: rootButton,
        startIcon: {
          marginLeft: 0,
          fontSize: '1rem',
          '& > :nth-of-type(1)': {
            fontSize: '1rem',
          },
        },
        endIcon: {
          marginLeft: 0,
        },
        menu: {
          fontWeight: 700,
          '& > .MuiButton-startIcon': {
            marginRight: 0,
          },
          ' .MuiButton-startIcon > .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        },
      },
    } as OverridesStyleRules,
    MuiIconButton: {
      styleOverrides: {
        root: rootIconButton,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '0.875rem',
          fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
          textDecoration: 'none',
          cursor: 'pointer',
          lineHeight: 'inherit',
          ' > svg': {
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: '1.25rem',
          flexDirection: 'row-reverse',
          padding: '0.75rem 1rem 0.75rem 0rem',
          gap: '0.25rem',
          alignSelf: 'stretch',
          backgroundColor: 'transparent',
          '&.Mui-disabled': {
            opacity: 1,
          },
          '& p': {
            fontSize: '.875rem',
            fontWeight: 700,
          },
        },
        expandIconWrapper: {
          transform: 'rotate(-90deg)',
          '&.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0.25rem',
          boxShadow:
            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
          backgroundImage: 'unset',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          fontSize: '0.875rem',
          fontWeight: 600,
          lineHeight: '0.875rem',
          letterSpacing: '0.025rem',
          '&.configureSchema': {
            height: '4.5rem',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          cursor: 'default',
          '&.preserve-whitespace > span': {
            whiteSpace: 'pre',
          },
        },
        clickable: {
          cursor: 'pointer',
        },
        outlined: {
          padding: '0rem 0.5rem',
          gap: '0.25rem',
          borderRadius: '0.25rem',
        },
        deleteIcon: {
          width: '0.875rem',
          height: '0.875rem',
          margin: '0 0 0 0',
        },
        label: {
          fontStyle: 'normal',
          fontSize: '0.75rem',
          fontWeight: 600,
          lineHeight: '1rem',
          letterSpacing: '0.025rem',
          padding: '0 0 0 0',
        },
        sizeMedium: {
          height: '1.5rem',
        },
        sizeSmall: {
          height: '1.25rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: 'fit-content',
          '&.active': {
            ' .MuiCardActionArea-focusHighlight': {
              opacity: 0,
            },
            ' .MuiCardActionArea-root': {
              cursor: 'default',
              pointerEvents: 'none',
            },
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: 'unset',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&.compact': { paddingBottom: 0, '&:last-child': { paddingBottom: 0 } },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontWeight: 700,
          cursor: 'default',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            pointerEvents: 'none',
            cursor: 'default',
          },
        },
      },
    },
    MuiDateRangeCalendar: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          fontWeight: 700,
          fontSize: '.875rem',
        },
        weekDayLabel: {
          fontWeight: 600,
          fontSize: '1rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '1rem',
          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        dayOutsideRangeInterval: {
          '@media (pointer: fine)': {
            ':hover:not(.MuiDateRangeCalendar-dayDragging)': {
              border: 'none',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputMultiline: {
          resize: 'both',
        },
        input: {
          paddingLeft: '0',
          paddingRight: '0.75rem',
          '::placeholder': {
            fontStyle: 'italic',
            opacity: 1,
            fontWeight: 400,
          },
          fontWeight: 600,
        },
        root: {
          paddingLeft: '.75rem',
          ':active > fieldset': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        shrink: {
          fontWeight: 600,
          lineHeight: 'unset',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '333px',
          padding: spacing,
          fontWeight: 600,
          fontSize: '.75rem',
          letterSpacing: '0.4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 700,
          letterSpacing: '.5px',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          width: 480,
        },
        action: {
          ' button:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          minHeight: 48,
          minWidth: 480,
          alignItems: 'center',
          width: '100%',
          padding: '12px',
          '&.sectional-banner': { minWidth: 472, paddingLeft: '8px', alignItems: 'center' },
        },
        icon: {
          padding: 0,
          height: '100%',
          fontSize: '1.5rem',
        },
        action: {
          padding: 0,
          marginRight: 0,
          height: '100%',
        },
        message: {
          padding: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0.125rem 0.875rem 0rem 0.875rem',
          fontWeight: 600,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: `${spacing * 3}px ${spacing * 4}px`,
          overflowY: 'scroll',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: 320,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 24,
          fontWeight: 700,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 24px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'outlined', size: 'extraSmall' },
              style: {
                // MuiInputBase and MuiOutlinedInput theming override these styles without this selector
                '& > .MuiInputBase-root': {
                  height: '2rem',
                },
              },
            },
          ],
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '16px',
          paddingRight: '16px',
          '@media (min-width: 600px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: 'default',
        },
        link: {
          ':not(.current)': { cursor: 'pointer' },
          '&.current': {
            pointer: 'default',
            textDecoration: 'underline',
          },
        },
      } as OverridesStyleRules,
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          animationDuration: '800ms',
        },
        circleIndeterminate: {
          strokeLinecap: 'round',
          strokeDasharray: '20px,120px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '1rem',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            border: 'none',
          },
          '& > span': {
            marginLeft: '-4px',
          },
        },
        avatar: {
          marginLeft: '-4px',
        },
      },
    },
    ...labStylings,
  },
});
