import { FlagType } from './types';
import { useFlag } from './useFlag';

/**
 * Just a convenience wrapper around `useFlag` to simplify the use of basic boolean flags.
 * Should only trigger rerenders when the flag changes.
 */
export function useFeature(key: string): boolean {
  const flag = useFlag(key);

  return flag && flag.type === FlagType.BOOLEAN ? flag.value : false;
}
