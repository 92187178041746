import { useContext, useEffect, useState } from 'react';
import { FlagContext } from './FlagContext';
import type { Flag, FlagListener } from './types';
import isEqual from 'lodash/isEqual';

/**
 * A hook for getting the current determination for a specific flag. This will return `undefined` if
 * the flag does not exist. The returned value should be reference stable unless its value changes.
 *
 * Note: this hook pulls back more detailed info about the flag because it supports flags other
 * than just booleans. If you just need a simple on/off check, see the `useFeature` hook instead.
 */
export function useFlag(key: string): Flag | undefined {
  const { getDeterminations, addFeatureListener, removeFeatureListener } = useContext(FlagContext);
  const [flag, setFlag] = useState(() => getDeterminations().get(key));

  useEffect(() => {
    const listener: FlagListener = value => {
      setFlag(existing => (isEqual(value, existing) ? existing : value));
    };
    addFeatureListener(key, listener);
    return () => removeFeatureListener(key, listener);
  }, [key, addFeatureListener, removeFeatureListener]);

  return flag;
}
